/**
 * Created by DWN on 4/17/2016.
 */
angular.module('app.editInPlace', []).directive('editInPlace', ['$document', '$timeout', '$rootScope', '$compile',
    function($document, $timeout, $rootScope, $compile) {

        var templates = {
            input: '<div ng-click="edit();$event.stopPropagation();">' +
            '<span>{{display || \'0\'}}</span>' +
            '<span class="show" style="display:none;">' +
            '<form ng-submit="submit()"><input ng-model="value" type="{{inputType}}" ng-model-options="{ debounce: 250 }"><input type="submit" style="display:none;"></form>' +
            '</span></div>',
            dropdown: '<div ng-click="edit();$event.stopPropagation();">' +
            '<span>{{display || \'undefined\'}}</span>' +
            '<span class="show" style="display:none;">' + '<ui-select ng-model="dropModel" on-select="updateModel(dropModel)" theme="select2" ng-disabled="disabled" style="min-width: 200px;">' +
            '<ui-select-match placeholder="Select">{{$select.selected.name}}</ui-select-match>' +
            '<ui-select-choices repeat="item in dropdown_items track by item.id" refresh="refresh($select.search, $select.open)" refresh-delay="100">' +
            '<div ng-bind="item.name"></div>' +
            '</ui-select-choices>' +
            '</ui-select>' +
            '</span></div>'
        }; //
    return {
        restrict: 'EA',
        scope: {
            value: '=',
            item: '=',
            column: '=',
            callback: '&callback',
            revisionCallback: '&revisionCallback',
            refreshDropdown: '&refreshDropdown',
            disable: "&disable",
            dropdownModel: '=',
            dropdownField: '=',
            display: '=',
            type: '=',
            inputType: '='
        },
        link: function(scope, element, attrs) {

            if(!scope.type)
                scope.type = 'input';

            if(!scope.inputType)
            {
                scope.inputType = 'text';
            }

            scope.dropdown_items = [];

            scope.open = false;

            element.html(templates[scope.type]).show();
            $compile(element.contents())(scope);

            scope.$on('uiSelect:open', function(event, opened){
                scope.refresh(null, opened);
            });

            scope.refresh = function(search, open) {
                if(open && search != null) {
                    scope.refreshDropdown({search: search}).success(function (res) {
                        _.each(res, function (item, index) {
                            res[index].name = item.full_name;
                        });
                        scope.dropdown_items = res;
                    });
                }
            };

            scope.updateModel = function(model) {
                scope.dropdownModel[scope.dropdownField] = model;
                scope.display = scope.item[scope.column.field];
                scope.callback();
            };

            var inputElement = $(element).find('input');
            var dataElement = $(element).find('span:nth-child(1)');
            var showElement = $(element).find('span:nth-child(2)');

            scope.submit = function() {
                scope.callback();
                scope.close();
            };

            inputElement.bind('blur', function($event) {
                    element.find('input').trigger('submit');
            });

            inputElement.bind('keydown', function($event) {
                if($event.which === 13) {
                    console.log('enter pressed');
                   // inputElement.blur();
                    //element.find('input').trigger('submit');
                }
            });

            $document.bind('click', function(event){
                scope.close();
            });

            scope.edit = function() {
                scope.editing = true;
                dataElement.hide();
                showElement.show();
                inputElement.focus();

                if(scope.inputType == 'date')
                {
                    var normalize = moment(scope.value).format("YYYY-MM-DD");
                    scope.value = moment(normalize).toDate();
                }
            };

            scope.close = function() {
                showElement.hide();
                dataElement.show();

                if(scope.inputType == 'date')
                {
                    var normalize = moment(scope.value).format("YYYY-MM-DD");
                    scope.value = normalize;
                    scope.display = normalize;
                }
                else
                {
                    //scope.display = scope.item[scope.column.field];
                }
            };

            scope.open_revisions = function() {
                scope.close();
                scope.revisionCallback();

            };


        }
    }
}]);