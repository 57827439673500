app.controller('ViewSurveyByBlockCtrl', ['$scope', 'PestSurveys', '$state', '$stateParams', 'block', '$location', 'ngTableParams', '$filter','$sce',
    function($scope, PestSurveys, $state, $stateParams, block, $location, ngTableParams, $filter,$sce) {

        $scope.block = block.data;
        $scope.surveys = {};
        $scope.gpsToURL = '';


        $scope.surveyTable = new ngTableParams({
            page: 1,
            count: 150
        }, {
            getData: function($defer, params) {


                var param = $location.search();
                param.page = params.page();

                PestSurveys.getByBlock($stateParams.blockId, param)
                    .success(function(data) {


                        var orderedData = params.sorting() ?
                            $filter('orderBy')(data.items.data, params.orderBy()) :
                            data.items.data;

                        params.total(data.items.total);
                        $defer.resolve(orderedData.slice((params.page() - 1) * params.count(), params.page() * params.count()));
                        $scope.surveys = data;

                        var obj = data.items.data;


                            var gpsName = [];
                            var gpsLong = [];
                            var gpsLat = [];
                            for (var i = 0, x = obj.length; i<x; i++){
                                var o = obj[i];
                                gpsName.push(o.thrips_count);
                                gpsLong.push(o.longitude);
                                gpsLat.push(o.latitude);
                            }
                        $scope.gpsToURL =  {name:gpsName,long:gpsLong,lat:gpsLat};


                        console.log( $scope.gpsToURL );
                        $scope.gpsToURL = $sce.trustAsResourceUrl('http://dwnmisc.app.appery.io/app/GPS.html?gps=' + encodeURIComponent(JSON.stringify($scope.gpsToURL)));

                        //console.log(encodeURIComponent(JSON.stringify(data)));
                        console.log($scope.gpsToURL);
                    });
            }
        });

    }

]);