app.factory('Locations', ['$rootScope', '$http', function($rootScope, $http) {

    return {
        list: function(params) {
            return $http.get('/locations', {params: params});
        },
        get: function(id) {

        },
        create: function(data) {

        }
    };
}]);