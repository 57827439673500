/**
 * Created by DWN on 3/22/2016.
 */
app.controller('TimesheetReportController', ['$scope', 'ngTableParams', 'Timesheet', 'Team', 'team', 'Users', '$location', '$rootScope',
    function($scope, ngTableParams, Timesheet, Team, team, Users, $location, $rootScope) {

        $scope.team = team.data;

        $scope.start_time = moment().subtract(7, 'days').toDate();
        $scope.end_time = moment().add(0, 'days').toDate();
        $scope.timesheets = [];
        
        var loaded = false;
        

        $scope.showName = function(member) {
            console.log(member);
        };

        $scope.editMember = function(member) {
            console.log(member);
        };

        $scope.$watchGroup(['start_time', 'end_time'], function(newValue, oldValue){

            var start = moment($scope.start_time).startOf('day');
            var end = moment($scope.end_time).endOf("day");

            var diff = end.diff(start, 'days');
            $scope.columns = [];

            for (var i = 0; i <= diff; i++) {
                $scope.columns.push({
                    name: moment(newValue[0]).add(i, 'days').format('ddd, Do'),
                    id: moment(newValue[0]).add(i, 'days').format("YYYY-MM-DD")
                });
            }
//
            team_leader_user_id = team.data.team_leader_user_id;
            Timesheet.get(team.data.team_leader_user_id, {
                params: {
                    start_time: start.format(),
                    end_time: end.format(),
                    group: true
                }
            }).success(function (res) {
                $scope.timesheets = res;
                loaded = true;
                timesheets = res;
                init(0);
            });
        });


        $scope.findHours = function(date, data) {
            var res = false;


            if (typeof($scope.test) == 'undefined')
            {
                $scope.test = test = {};
            }
                if (typeof($scope.test[date]) == 'undefined')
            {
                test[date] = 0;
            }

            try {
                res = data[date].billable_hours;

                if(res == null && moment(data[date].start_time).format("HH:mm") > "00:00")
                {
                    res = 'IN';
                }

                if(data[date].absent)
                {
                    res = 'abs';
                }
            }
            catch(e)
            {

            }

            if(loaded && res === false)
                res = 'N/A';

            if(!loaded)
                res = 'loading...';

            if (res === ' '){res = 'IN';}
            return res;
        };

        $scope.teams = new ngTableParams({
            page: 1,
            count: 100
        }, {
            getData: function($defer, params) {
                Team.list({
                    params: {
                        start_time: $scope.start_time,
                        end_time: $scope.end_time,
                        with_hours: 1
                    }
                }).success(function(res) {
                    var data = [];

                    data = _.reject(res.data, function(item) {
                        if(item.members.length < 1) {
                            return true;
                        }

                        if(item.leader.first_name === 'Limbo') {
                            return true;
                        }

                        return false;
                    });
                    params.total(data.length);
                    $defer.resolve(data);
                });
            }
        });

        $scope.editUser = function(user) {
            Users.edit(user.id, user)
                .success(function(res){
                    
                });
        };
        
        $scope.editPayrate = function(user) {
            Users.edit(user.id, user).success(function(res){

            }).error(function(res){
                alert(res.message);
            });
        };

        $scope.showEdit = function($event) {
            $($event.target).parent().find('input').show();
            $($event.target).hide();
            $($event.target).parent().find('input').focus();
        };

        /*
        $scope.editBlur = function(member, $event) {

            var split = member.user.full_name.split(" ");

            var last_name = split[split.length-1];
            var first_name = split.splice(0, split.indexOf(last_name)).join(" ");

            Users.edit(member.user.id, {first_name: first_name, last_name: last_name}).success(function(res){
                alert('user saved!');
            });

            $($event.target).parent().find('span').show();
            $($event.target).hide();
        };
        */

        $scope.findCounts = function(date) {
            try {
                var workers = $scope.timesheets.workers[date];
                var absences = $scope.timesheets.absences[date];
            }
            catch(e)
            {

            }

            if(typeof workers == "undefined")
                var workers = 'NA';

            if(typeof absences == 'undefined')
                var absences = 'NA';

            return workers + ' / '+absences;
        };

        $scope.findHere = function(column_i){
//
            var crew_count = 0;
            var absent_count = 0;
            var el = $('#timesheet_table')[0];
            var rows = el.rows.length;
            for (var i = 2; i < rows ; i++){
                var hours = el.rows[i].cells[column_i].innerHTML;
                if (hours.indexOf('abs') > -1){
                    absent_count++;
                } else if (Number(hours) > -1 || hours.indexOf('IN') > -1){
                    crew_count++;

                }

            }

            return crew_count + " / " + absent_count;

        };

        function init(i) {

            if (typeof(taskHours_innitialize) != "function" && i < 10) {
                setTimeout(function() { init(i+1)}, 200);
            }
            else {
                taskHours_innitialize();
            }
        }

        $.getScript('http://app.davewilson.tech/js/appery%20global/global.js').done(function(){
            $.getScript('http://app.davewilson.tech/js/timesheets/dwn/taskHours.js').done(function(){
                //taskHours_innitialize();
            });
        }
        );
}]);

