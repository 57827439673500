'use strict';

/* Directives */
function DatePicker(){
    return {
        require: 'ngModel',
        restrict: 'A',
        scope: { format: "=" },
        link: function(scope, element, attrs, ngModel){
            if(typeof(scope.format) == "undefined"){ scope.format = "yyyy-mm-dd hh:ii" }
            $(element).fdatepicker({
                format: scope.format,
                pickTime: true
            }).on('changeDate', function(ev){
                scope.$apply(function() {
                    ngModel.$setViewValue(ev.date);
                });
            })
        }
    }
}

angular
    .module('datePicker', [])
    .directive('datepicker', DatePicker);