var app = angular.module('app', ['mm.foundation', 'ui.router', 'ngTable', 'ui.select', 'ngSanitize', 'datePicker', 'app.editInPlace',
    'app.barcodeScan', 'app.barcodeListener', 'angular-click-outside', 'anguFixedHeaderTable', 'app.blocks']);

app.run(['$rootScope', 'Revisions', '$http', '$location', '$window', '$timeout', 'Products',
    function($rootScope, Revisions, $http, $location, $window, $timeout, Products) {
    $rootScope.moment = moment;
    $rootScope._ = _;

    var shortcut = {
        which: 94,
        times: 2,
        count:0,
        timeoutMs: 50,
        lastPress: 0,
        active: false,
        endKey: 13,
        buffer: [],
        eventBuffer: []
    };

    $(document).on('keypress', function(e) {
        if(e.which == shortcut.endKey && shortcut.active) {
            shortcut.active = false;
            var payload = {location: parse_barcode(shortcut.buffer.join("")), barcode: shortcut.buffer.join("")};

            $rootScope.$emit('barcodeShortcut', payload);
            $rootScope.$emit('barcodeShortcut.'+payload.location.control, payload);

            shortcut.buffer = [];
        }
        else if(shortcut.active) {
            e.preventDefault();
            shortcut.buffer.push(String.fromCharCode(e.which));
        }

        if(e.which == shortcut.which) {
            e.preventDefault();
            shortcut.active = true;
            shortcut.buffer = [];
        }

    });

    $rootScope.logout = function() {
        var url = $location.search();
        localStorage.setItem('oldPath', JSON.stringify(url));
        $window.location = '/logout?path='+$location.path();
    };

        var oldpath = localStorage.getItem('oldPath');

        if(oldpath)
        {
            localStorage.removeItem('oldPath');
            try {
                $location.search(JSON.parse(oldpath));
            }
            catch(e)
            {

            }
        }


    $rootScope.help = function() {
        var help = prompt('HELP REQUEST');

        $http.post('/email', {
            to: 'andrew@tilvid.com',
            subject: 'HELP REQUEST',
            body: help,
            page: $location.path()
        });
        $http.post('/email', {
            to: '2095420272@vtext.com',
            subject: 'HELP REQUEST',
            body: help,
            page: $location.path()
        });
        $http.post('/email', {
            to: '2092898939@vtext.com',
            subject: 'HELP REQUEST',
            body: help,
            page: $location.path()
        });

    };

    $rootScope.products = function() {
        Products.selectProductModal();
    };

    /*
    scaleTables();

    $rootScope.$watch(function(){return $window.innerWidth;}, function(value) {
            console.log($window.innerWidth, document.body.clientWidth);
            scaleTables();
        });

    function scaleTables() {
        $timeout(function() {
            $("table.scale").each(function(index, table) {
                var t = $(this);
                var scale = ($window.innerWidth-30)/t.innerWidth();
                console.log("scale table",scale);
                t.attr("style", "transform:scale("+scale+");transform-origin:0 0;");
            });
        });
    }
    */

}]);

app.config(['$httpProvider', function($httpProvider) {

    $httpProvider.interceptors.push(['$location', '$q', function($location, $q) {
        return {
            'responseError' : function(response) {
                if(response.status == 401)
                {
                    var path = $location.path();
                    localStorage.setItem('oldPath', JSON.stringify($location.search()));
                    $location.path('/login').search({message: 'You must be logged in to access this page.', redirectUrl: path});
                }
                else
                {
                    return $q.reject(response);
                }
            }
        };
    }]);
}]);
app.config(['$provide', function ($provide) {
    $provide.decorator('uiSelectDirective',['$delegate', function ($delegate) {

        var directive = $delegate[0];
        var directiveCompile = directive.compile;

        directive.compile = function (tElement, tAttrs) {
            var link = directiveCompile.apply(this, arguments);
            return function(scope, elem, attrs) {
                link.apply(this, arguments);
                scope.$watch('$select.open', function(val) {
                    scope.$parent.$broadcast('uiSelect:open', val);
                });
            };
        };

        return $delegate;

    }]);
}]);
