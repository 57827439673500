app.factory('WorkOrderCategories', ['$rootScope', '$http', function($rootScope, $http) {

    return {
        list: function(params) {
            return $http.get('/work-orders/categories', { params: params});
        },
        get: function(id) {

        },
        create: function(data) {

        }
    };
}]);