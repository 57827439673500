app.controller('FertilizingReportsController', ['$scope', 'reports', function($scope, reports){
    console.log(reports);
}]);

app.controller('NewFertilizingReportController', ['$scope', function($scope) {

    $scope.report = {
        start_date: new Date(),
        date: new Date(),
        attributes: [{
                name:'',
                unit: '',
                unit_value: ''
            }],
        tasks: [
            {
                block: '',
                nursery_type: '',
                employee: '',
                target_lbs: ''
            }
        ]
    };

    $scope.create = function() {
        
    }

    var report_copy = angular.copy($scope.report);

    $scope.$watch('report.fertilizers', function(newValue, oldValue){
        console.log(newValue);
        if(newValue[newValue.length-1].name.length > 0)
        {
            $scope.report.fertilizers.push(angular.copy(report_copy.fertilizers[0]));
        }
    }, true);

    $scope.$watch('report.blocks_applied', function(newValue){
        console.log(newValue);
        if(newValue[newValue.length-1].block.length > 0)
        {
            $scope.report.blocks_applied.push(angular.copy(report_copy.blocks_applied[0]));
        }
    }, true);
}]);