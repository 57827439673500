/**
 * Created by andrewlamers on 8/5/16.
 */
app.controller('ShipmentHistoryModalController', ['$scope', 'Shipment', 'history', '$modalInstance',
    function($scope, Shipment, history, $modalInstance) {
        $scope.history = history.data.data;

        $scope.cancel = function() {
            $modalInstance.dismiss('cancel');
        }
    }
]);

app.controller('ShipmentsTableCtrl', ['$scope', '$modal', 'Shipment', '$location', 'ngTableParams',
    function($scope, $modal, Shipment, $location, ngTableParams) {
        $scope.$parent.shipmentsTable = new ngTableParams({
                page: 1,
                count: 15,
                sorting: $scope.$parent.default_sort
            }, {
                counts: [],
                getData: function($defer, params) {
                    var param = $scope.$parent.param;
                    param.page = params.page();
                    param.per_page = params.count();
                    param.orderBy = params.orderBy();

                    Shipment.list(param)
                        .success(function(data) {
                            params.total(data.total);
                            $defer.resolve(data.data);
                            $scope.shipments = data;
                        });
                }
            });

        $scope.transfer = function(id) {
            var modal = $modal.open({
                templateUrl: '/partials/shipments/modals/transfer.html',
                controller: 'TransferShipmentModalController',
                backdrop: 'static',
                resolve: {
                    shipment: ['Shipment', function(Shipment){
                        return Shipment.get(id);
                    }],
                    containerTypes: ['ContainerType', function(ContainerType) {

                        return ContainerType.list();
                    }],
                    history: ['TransferLog', function(TransferLog) {
                        return TransferLog.list({shipment_id: id, orderBy:'-updated_at'});
                    }]
                }
            });

            modal.result.then(function (res) {

                $scope.$parent.shipmentsTable.reload();
                $scope.$parent.reload_completed();

                $scope.alerts = [
                    {
                        msg: res.total_quantity+' '+res.shipment.root_stock.name+' transferred to '+res.block,
                        type:'success'
                    }
                ];

            });
        };
}]);
app.controller('ShipmentCtrl', ['$scope', 'shipments', '$modal', 'Shipment', '$location', 'ngTableParams',
    function($scope, shipments, $modal, Shipment, $location, ngTableParams) {

    $scope.alerts = [];

    $scope.scan = function(barcode) {
        console.log(barcode);
    };

    $scope.shipmentsTable = {};
    $scope.default_sort = {
        'shipments.date': 'asc'
    };

    $scope.param = {
        status: 'RECEIVED'
    };

    $scope.completed_shipments = shipments.data;

    $scope.reload_completed = function() {
        Shipment.list({status: 'ARCHIVE', orderBy: '-updated_at'}).success(function(res){
            $scope.completed_shipments = res;
        });
    };

    $scope.history = function(id) {
        var modal = $modal.open({
            templateUrl: '/partials/shipments/modals/history.html',
            controller: 'ShipmentHistoryModalController',
            backdrop: 'static',
            resolve: {
                history: ['TransferLog', function(TransferLog) {
                    return TransferLog.list({shipment_id: id, orderBy:'-updated_at'});
                }]
            }
        });
    };

}]);

app.controller('TransferShipmentModalController', ['$scope', 'Blocks', '$modalInstance', 'Shipment', 'shipment', 'ContainerType', 'containerTypes', '$timeout', 'history', '$modal',
    function($scope, Blocks, $modalInstance, Shipment, shipment, ContainerType, containerTypes, $timeout, history, $modal) {

    $scope.shipment = shipment.data;
    $scope.containerTypes = containerTypes.data.data;
    $scope.history = history.data.data;

    $scope.options = {
        machine: 0,
        start_date: new Date(),
        start_time: new Date(),
        location: {},
        block: {}
    };

    $scope.transfer = {
        shipment_id: shipment.data.id,
        total_quantity: 0,
        container_quantity: 0,
        container_type: _.findWhere($scope.containerTypes, {id: shipment.data.container_type_id}),
        block: {}
    };

    $scope.blocks = [
        {block: 'EliPottingMachine'},
        {block: 'BluePottingMachine'},
        {block: 'GreenPottingMachine'}
    ];

    $timeout(function(){
        $('#transferTo > a').click();
    });

    $scope.selectBlock = function() {
        $timeout(function(){
            $("#noContainers").focus().select();
        });
    };

    $scope.refreshBlocks = function(block, filters, scope) {

        var params = {
            block: block
        };

        if(!filters)
            params.inventory_filters = $scope.inventory_filters;

        return Blocks.list({params: params})
            .success(function(res) {

                if(scope)
                    $scope[scope] = res;
                else
                    $scope.blocks = res;
            })
    };

    $scope.$watchGroup(['transfer.container_type', 'transfer.container_quantity'], function(newValue, oldValue) {
        $scope.transfer.total_quantity = ($scope.transfer.container_type.quantity_capacity * $scope.transfer.container_quantity);
    });

    $scope.cancel = function() {
        $modalInstance.dismiss('cancel');
    };

    $scope.submit = function() {
        var params = _.extend({}, $scope.transfer);
        params.block = params.block.block;
        params.shipment = $scope.shipment;

        Shipment.transfer(params).success(function(res) {
            if(res.payload.confirm) {
                console.log("confirm", res);
                var modal = $modal.open({
                    templateUrl: '/partials/modals/inventory/confirm-transfer.html',
                    controller: 'ConfirmTransferModalController',
                    backdrop: 'static',
                    resolve: {
                        data: function() { return res; }
                    }
                });

                modal.result.then(function (selectedItem) {
                    Shipment.transfer(_.extend({}, params, {confirm: false})).success(function(res2) {
                        alert('Item transferred.');
                        $modalInstance.close(params);
                    }).error(function(res2){
                        $scope.alerts = res2.messages;
                    });
                }, function () {
                    $modalInstance.dismiss('cancel');
                });

            } else {
                $modalInstance.close(params);
            }
        }).error(function(res){
            $scope.alerts = res.messages;
        });
    };
}]);

app.controller('CreateShipmentCtrl', ['$scope', 'RootStocks', 'Shipment', '$http', 'containerTypes', '$timeout', 'ngTableParams', '$window',
    function($scope, RootStocks, Shipment, $http, containerTypes, $timeout, ngTableParams, $window) {

    $scope.containerTypes = containerTypes.data.data;
    $scope.alerts = [];
    $scope.load_time = moment().format();
    $scope.shipmentsTable = {};
    $scope.default_sort = {
        'shipments.created_at': 'desc'
    };
    $scope.param = {
        status: 'RECEIVED'
    };

    function reset() {
        $scope.shipment = {
            container_quantity: 0,
            quantity_per_container: 0,
            total_quantity: 0,
            to_location: "Rain Barn",
            from_customer: "Micro Paradox",
            container_type: {},
            root_stock: {}
        };
    }
    reset();
    $scope.shipment.date = new Date();

    $scope.container = function() {
        $scope.shipment.quantity_per_container = $scope.shipment.container_type.quantity_capacity;

        $timeout(function(){
            $("#rootStock > a").click();
        });
    };

    $scope.rootstockSelect = function() {
        console.log("select");
        $timeout(function(){
            $("#noTrays").focus().select();
        });
    };

    $timeout(function(){
        $("#containerType>a").click();
    });

    $scope.root_stocks = [];
    $scope.refreshRootStocks = function(variety, filters, scope) {

        var params = {
            name: variety
        };

        return RootStocks.list({params: params})
            .success(function(res) {

                if(scope)
                    $scope[scope] = res;
                else
                    $scope.root_stocks = res;
            });
    };

    $scope.$watchGroup(['shipment.container_quantity', 'shipment.quantity_per_container'], function(newValue, oldValue){
        $scope.shipment.total_quantity = $scope.shipment.container_quantity * $scope.shipment.quantity_per_container;
    });

    $scope.focusCt = function($select) {
        $scope.$broadcast('CtFocus');
    };

    $scope.submit = function() {
        $scope.shipment.root_stock_id = $scope.shipment.root_stock.id;
        $scope.shipment.shipment_direction = 'incoming';
        $scope.shipment.container_id = 0;
        $scope.shipment.container_type_id = 0;
        $scope.shipment.status = 'CREATED';

        Shipment.create($scope.shipment).success(function(res){
            $scope.alerts = res.messages;
            var date = $scope.shipment.date;
            reset();
            $scope.shipment.date = date;
            $scope.shipmentsTable.reload();
        }).error(function(res){
            $scope.alerts = res.messages;
        });
    };

    $scope.getCustomers = function(search) {
        return $http.get('/customers', {params: {
            full_name: search
        }}).then(function(res){
            var customers = [];
            angular.forEach(res.data.data, function(customer) {
                customers.push(customer.full_name);
            });

            return customers;
        });
    };

    $scope.getLocations = function(search) {
        return $http.get('/locations', {params: {
            name: search
        }}).then(function(res){
            var customers = [];
            angular.forEach(res.data, function(customer) {
                customers.push(customer.name);
            });

            return customers;
        });
    };
}]);
