app.factory('Blocks', ['$rootScope', '$http', function($rootScope, $http) {

    return {
        list: function(filters) {
            return $http.get('/blocks', filters);
        },
        get: function(id) {
            return $http.get('/blocks/'+id);
        },
        create: function(data) {

        },
        byName: function(block) {
            return $http.get('/blocks/name/'+block);
        }
    };
}]);