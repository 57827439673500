app.controller('GradingCountsController', ['$scope', '$modal', 'Products', 'sizes', 'InventoryItems', 'branches', '$window', '$location', '$timeout',
    function($scope, $modal, Products, sizes, InventoryItems, branches, $window, $location, $timeout) {

        var defaults = $location.search();

    $scope.count = {};

    $scope.size_types = ['Caliper', 'Branches', 'Whip', 'Height'];

    $scope.count.size_type = 'Caliper';
    $scope.finalized = false;

    $scope.$watch('finalized', function(newValue, oldValue) {
        console.log(oldValue, newValue);
        $timeout(function() {
            if(newValue === true) {
                $("#finalizeButton").html('Finalized').removeClass("secondary").addClass("warning");
            } else {
                $("#finalizeButton").html('Finalize?').removeClass("warning").addClass("secondary");
            }
        });
    });

    $scope.reset = function() {
        console.log(sizes);
        var s = JSON.parse(JSON.stringify(sizes));
        $scope.count.sizes = s.data[$scope.count.size_type];
        $scope.count.product = {};

        if(defaults.branch_id) {
            $scope.count.branch_id = defaults.branch_id;
            $scope.count.source = defaults.branch_id;
        }

        if(defaults.destination) {
            $scope.count.destination = defaults.destination;
        }
    };

    $scope.sizeType = function() {
        $scope.reset();
    };

    $scope.reset();

    $scope.branches = branches.data;

    $scope.destinations = ['Trenches', 'Customer', 'Cold Storage', 'Reedley'];

    $scope.selectProduct = function() {
        $window.scrollTo(0, 0);
        setTimeout(function(){
            Products.selectProductModal(function(res){
                $scope.count.product = res;
            })
        }, 200);
    };

    $scope.branch = function() {
        $scope.count.source = $scope.count.branch_id;
    };

    $scope.search = function() {
        $scope.getItems();
        $scope.finalized = false;

    };

    $scope.new = function() {
        $scope.count = {};
        $scope.pallet_id = null;
        $scope.reset();
        $scope.items = {};
    };

    $scope.printTag = function(id) {
        $window.open('/pallet/'+id+'/print?salesYear=2020', "print_pallet", "height=600,width=800");
    };

    $scope.finalizePallet = _.throttle(function(id) {
        InventoryItems.finalizePallet(id, {salesYear: 2020, finalize: !$scope.finalized}).success(function(res){
            $scope.getItems();
        });

    }, 1000);

    $scope.getItems = function() {

        console.log($scope.pallet_id);

        if($scope.pallet_id > 0) {
            InventoryItems.list({
                blockName: 'pallet',
                rowNumber: $scope.pallet_id,
                salesYear: 2020,
                per_page: 1000
            }).success(function (res) {
                $scope.count.pallet_id = $scope.pallet_id;
                $scope.items = res;

                try {
                    if (res.items.data[0].inventory_status.name === 'pallet finalized') {
                        $scope.finalized = true;
                    } else {
                        $scope.finalized = false;
                    }
                } catch(e) {

                }

                if(res.items && res.items.data.length > 0) {
                    var meta = JSON.parse(res.items.data[0].metadata);
                    $scope.count.branch_id = res.items.data[0].branch_id;
                    $scope.count.destination = meta.destination;
                    $scope.count.source = res.items.data[0].source.name;
                }

            });
        }
    };

    $scope.remove = function(item) {
        InventoryItems.delete(item.id).success(function(res) {
            $scope.getItems();
        })
    };

    $scope.add = function() {

        if(!$scope.count.product.id) {
            $scope.selectProduct();
        }
        else {

            InventoryItems.createForPallet($scope.pallet_id, $scope.count).success(function(res) {
                $scope.pallet_id = res.payload.pallet_id;
                $scope.getItems();
                $scope.reset();
            }).error(function(res){
                alert(res.messages[0].message);
            });
        }

    };

}]);