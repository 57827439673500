app.factory('Orders', ['$rootScope', '$http', '$httpParamSerializer', function($rootScope, $http, $httpParamSerializer) {

    return {
        list: function(params) {
            return $http.get('/orders', {params: params});
        },
        get: function(id) {
            return $http.get('/orders/'+id);
        },
        search: function(query) {
            return $http.get('/orders/search', {params: query});
        },
        searchItems: function(query) {
            return $http.get('/orders/items/search', {params: query})
        },
        pullLineItem: function(id) {
            return $http.get('/orders/items/'+id+'/pull');
        }
    };
}]);