app.factory('ContainerType', ['$rootScope', '$http', function($rootScope, $http) {

    return {
        list: function(filters) {
            return $http.get('/container-types', {params: filters});
        },
        get: function(id) {
            return $http.get('/container-types/'+id);
        },
        create: function(data) {
            return $http.post('/container-types', data);
        }
    };
}]);