app.factory('Users', ['$rootScope', '$http', function($rootScope, $http) {

    return {
        list: function(filters) {
            return $http.get('/users', {params: filters});
        },
        get: function(id) {

        },
        create: function(data) {

        },
        edit: function(id, data) {
            return $http.post('/users/'+id, data);
        }
    };
}]);