app.controller('NewBuddingPieceworkController', ['$scope', 'Blocks', 'Team', 'BuddingTemp', '$q', '$timeout', 'InventoryItems', 'ngTableParams', 'Users',
    function($scope, Blocks, Team, BuddingTemp, $q, $timeout, InventoryItems, ngTableParams, Users) {
        var save = _.debounce(function(callback) {



        }, 2000);

        $scope.budders = [];
        $scope.budder = {
            selected: {}
        };

        $scope.refreshBudders2 = function(search) {

            if (search != null){
                return Users.list({name: search, has: 'dwn_id'}).success(function(res){
                    $scope.budders = res;
                });
            }

        };

    $scope.bud = {
        date: new Date(),
        buds: [],
        crew: {},
        block: {}
    };

    $scope.types = [
        {
            name: 'Piecework',
            value: 'p'
        },
        {
            name: 'Hourly',
            value: 'h'
        }
    ];

    $scope.types.selected = {
        name: 'Piecework',
        value: 'p'
    };

    $scope.blocks = {};
    $scope.refreshBlocks = function(block, filters, scope) {

        var params = {
            block: block
        };

        if(!filters)
            params.inventory_filters = $scope.inventory_filters;

        return Blocks.list({params: params})
            .success(function(res) {

                if(scope)
                    $scope[scope] = res;
                else
                    $scope.blocks = res;
            })
    };

    $scope.crews = {};
    $scope.refreshCrews = function(name) {
        return Team.list({params: {limit: 200}}).success(function(res) {
            $scope.crews = res.data;
        });
    };

    $scope.refreshBudders = function(name) {
        return Team.get($scope.bud.crew.id).success(function(res) {
            $scope.budders = res;
        });
    };

    $scope.refreshTiers = function(name) {
        return Team.get($scope.bud.crew.id).success(function(res) {
            $scope.tiers = res;
        });
    };

    $scope.buds = [{}];

    $scope.$watch('bud.crew', function(newValue){
        $scope.refreshBudders();
        $scope.refreshTiers();
    },true);


    $scope.$watch('bud.buds', _.debounce(function(newValue, oldValue) {

        BuddingTemp.save({data: $scope.bud}).success(function (res) {
            $scope.bud.buds = res;
        });

    },5000), true);

    $scope.$watchGroup(['bud.block', 'bud.block_row_start', 'bud.block_row_end'], function(newValue){
        refreshTable();
    });

    function refreshTable() {
        BuddingTemp.list({block: $scope.bud.block.name, block_row_start: $scope.bud.block_row_start, block_row_end: $scope.bud.block_row_end})
            .success(function(res) {

                if(res.length < 1)
                    res = [{}];
                else
                    res.push({});

                $scope.bud.buds = res;
            });
    }

    $scope.inventoryTable = new ngTableParams({
        page: 1,
        count: 8,
        sorting: {
            'inventory_items.id': 'desc'
        }
    }, {

        getData: function($defer, params) {

            var param = {};
            /*
            if($scope.inventory_filters.block)
                param.blockId = $scope.inventory_filters.block.id;

            if($scope.inventory_filters.type)
                param.typeId = $scope.inventory_filters.type.id;

            if($scope.inventory_filters.variety)
                param.varietyId = $scope.inventory_filters.variety.id;

            if($scope.inventory_filters.root_stock)
                param.rootStockId = $scope.inventory_filters.root_stock.id;

            if($scope.inventory_filters.inventory_number)
                param.inventory_number = $scope.inventory_filters.inventory_number;

            if($scope.inventory_filters.sales_year)
                param.salesYear = $scope.inventory_filters.sales_year.sales_year;

            if($scope.inventory_filters.branch)
                param.branchId = $scope.inventory_filters.branch.id;

            if($scope.inventory_filters.group_by)
            {
                param.groupBy = $scope.inventory_filters.group_by;
            }

            if($scope.inventory_filters.block_row_start)
            {
                 = $scope.inventory_filters.block_row_start;
            }

            if($scope.inventory_filters.block_row_end)
            {
                param.blockRowEnd = $scope.inventory_filters.block_row_end;
            }

            if($scope.inventory_filters.start_date)
            {
                param.start_date = $scope.inventory_filters.start_date;
            }

            if($scope.inventory_filters.end_date)
            {
                param.end_date = $scope.inventory_filters.end_date;
            }

            if($scope.inventory_filters.date_type)
            {
                param.date_type = $scope.inventory_filters.date_type;
            }

            if($scope.inventory_filters.metadata) {
                param.metadata = $scope.inventory_filters.metadata;
            }
*/
            param.blockRowStart = 1;
            param.page = params.page();
            param.per_page = params.count();
            param.orderBy = params.orderBy();

            InventoryItems.list(param)
                .success(function(data) {
                    params.total(data.items.total);
                    $defer.resolve(data.items.data);
                    $scope.inventoryItems = data;
                });
        }
    });

        $scope.clear = function() {
            $scope.budders.selected = {};
        };


        $('.container').after('<div id="dataContainer">Select dates and load data</div>');

        $scope.exportCsv = function() {
            let url = 'https://field.davewilson.tech/v2/reports/piecework?start_date='+ $('#start_date').val() + ' 00:00' +
                '&end_date=' + $('#end_date').val() + ' 23:59' + '&budder_type=' + $scope.types.selected.value;

            window.open(url);
        }

    $scope.test = function() {
        $('#dataContainer').empty();
        $('#dataContainer').append('<div>LOADING.....</div>');
        console.log('test');
        console.log('running');
        var param = {};
        $scope.inventoryTable.reload();

        param.page = 1;
        param.per_page = 825;
        param.dwn_id = true;
        param.cvl_id = true;
        param.date_type = "bud_date";
        param.start_date = $('#start_date').val() + " 00:00";
        param.end_date = $('#end_date').val() + " 23:59";
        //param.exclude_blocks = '"P3A", "P3B"';
        //param.exclude_blocks = 'R4D';
        param.hourly = false;
        param.piecework = true;

        if($scope.types.selected.value == 'h') {
            param.piecework = false;
            param.hourly = true;
        } else if($scope.types.selected.value == "p") {
            param.piecework = true;
            param.hourly = false;
        } else {
            param.piecework = true;
        }

        if($scope.budder.selected)
        {
            param.budder_tier_id = $scope.budder.selected.dwn_id;
            console.log(param.user);
        }

        //param.orderBy = params.orderBy();

        InventoryItems.list(param)
            .success(function(data) {
                //params.total(data.items.total);
                //$defer.resolve(data.items.data);
                $scope.inventoryItems = data;

                populateTables(data, $scope.per_page);
            });
    }

}]);

function populateTables(data, per_page){
    $('#dataContainer').empty();
    $('#dataContainer').append('<style>@media print{a[href]:after{content:none}}</style><h2>Unassigned Piece Work</h2><div id="unassigned_piece_work"></div><hr>');
    $('#dataContainer').append('<h2>CVL Piece Work</h2><div id="cvl_piece_work"></div><hr>');
    $('#dataContainer').append('<h2>DWN Piece Work</h2><div id="dwn_piece_work"></div><hr>');

    console.log(data.items);
    var obj = data.items.data;


    console.log(obj);
    for (var i = 0, x = obj.length; i< x; i++)
    {

        var o = obj[i];

        //exclude potted blocks
        if (o.block.name == "P3A" || o.block.name == "P3B"){
        continue;
        }


        console.log(o,o.tier,(o.tier == null));
        var budderId = o.budded_by_user_id;
        var budderType = o.budder_type;
        if (o.tier == null){
            var tierId = "Unassigned";
        } else {
            var tierId = o.tier.dwn_id;
        }

        var tierType = o.tier_type;
        //console.log(budderId,(typeof($('#dwn_id' + budderId))));
        if (typeof($('#dwn_id' + budderId)[0]) == 'undefined'){
            createNewTable('budder',budderId,o, per_page);
        } else {
            appendTable('budder',budderId,o);
        }
        if (typeof($('#dwn_id' + tierId)[0]) == 'undefined'){
            createNewTable('tier',tierId,o, per_page);
        } else {
            appendTable('tier',tierId,o);
        }
    }
    calculateTotals();

}

function calculateTotals(){
debugger;

    var totalBuddedByCVL = 0;
    var totalBuddedByDWN = 0;
    var totalTiedByCVL = 0;
    var totalTiedByDWN = 0;

    if ($('[ng-model=summary]')[0].value == "on"){
        var summary = true;
    }
    if (summary && $('#summaryTable').length == 0){
        $('#dataContainer').append('<table id="summaryTable"><th>Name</th><th>CVL id</th><th>total</th><th>2 days</th><th>3 days</th><th>4 days</th><th>5 days</th><th>6 days</th><table>');
    }
    dateRangeBudded = 0;
    var obj = $('.tableCounts');
    for (var i = 0, x = obj.length; i < x; i++)
    {
        var total_tied = 0;
        var total_budded = 0;
        var o = $(obj[i]);

        for (var j = 0, y = o[0].rows.length -1; j < y; j++)
        {
            total_tied += Number(o.find('.tiedQty')[j].innerHTML);
            total_budded +=Number(o.find('.buddedQty')[j].innerHTML)
        }
        dateRangeBudded += total_budded;

        //debugger;
        var nStr2 = '';
        if ($('[ng-model=summary]')[0].value == "on"){
            //debugger;
            nStr2 = '<tr><td>' + o[0].attributes.worker.value + '</td><td>' +o[0].attributes.cvl_id.value + '</td>'

        }

        var nStr = '<tfoot><tr><td></td><td></td><td>Totals:</td><td>' + total_tied + '</td><td>' + total_budded + '</td></tr>';
        if (total_budded > 0) {
            nStr2 +='<td>' + total_budded + ' budded</td><td>' + Math.round(total_budded / 2) + '</td><td>' + Math.round(total_budded / 3) + '</td><td>' + Math.round(total_budded / 4) + '</td><td>' + Math.round(total_budded / 5) + '</td><td>' + Math.round(total_budded / 6) + '</td>';

        }
        
        if (total_tied > 0) {
            if (total_budded > 0) {
                nStr2 += '</tr><tr><td>' + o[0].attributes.worker.value + '</td><td>' +o[0].attributes.cvl_id.value + '</td>';
            } else {
               // nStr2 += '<td>' + o[0].attributes.worker.value + '</td><td>' +o[0].attributes.cvl_id.value + '</td>';
            }
            nStr2 += '<td>' + total_tied + ' tied</td><td>' + Math.round(total_tied / 2) + '</td><td>' + Math.round(total_tied / 3) + '</td><td>' + Math.round(total_tied / 4) + '</td><td>' + Math.round(total_tied / 5) + '</td><td>' + Math.round(total_tied / 6) + '</td>'

        }

        nStr +=    '</tfoot>';
        nStr2+= '</tr>';

        if (summary && o[0].attributes.dwn_id.value > 9000 && nStr2 != ''){
            $('#summaryTable').append(nStr2);
            totalBuddedByCVL += total_budded;
            totalTiedByCVL += total_tied;
        } else {
            totalBuddedByDWN += total_budded;
            totalTiedByDWN += total_tied;
        }
        o.append(nStr)
    }

    $('#totalBudded').empty();
    nStr = '<div id="totalBudded">Total Budded: ' + dateRangeBudded +
        '<br>Total buddde by DWN: ' + totalBuddedByDWN +
        '<br>Total Budded by CVL: ' + totalBuddedByCVL +
        '<br>Total Tied: ' + (totalTiedByCVL + totalTiedByDWN) +
        '<br>Total Tied by DWN: ' + totalTiedByDWN +
        '<br>Total Tied by CVL: ' + totalTiedByCVL +
        '</div>';
    $('[name=loadButton]').after(nStr);
    $('#summaryTable').before(nStr);

}
function createNewTable(worker_type,dwn_id,obj, per_page){


    if (worker_type == 'budder'){
        if (obj.budder !== null)
        {
            console.log(obj);

        var workerName = obj.budder.full_name;
            var item_user_id = obj.budder.id;
            var cvlId = obj.budder.cvl_id;
        } else {
            var workerName = 'Unassigned';
            var cvlId = 'Unassigned';

        }

    } else {
        if (obj.tier !== null)
        {
            var workerName = obj.tier.full_name;
            var cvlId = obj.tier.cvl_id;
            var item_user_id = obj.tier.id;
        } else {
            var workerName = 'Unassigned';
            var cvlId = 'Unassigned';
        }

    }
    if (dwn_id < 9000 && cvlId < 1){
        //workerName = 'Unassigned';
        console.log(workerName,dwn_id);
        var el = $('#dwn_piece_work');
    } else if (cvlId == 'Unassigned') {
        var el = $('#unassigned_piece_work');
    } else {
        var el = $('#cvl_piece_work');
    }

    if (dwn_id == 0){
        workerName = 'Unassigned';
        var el = $('#unassigned_piece_work');
    }



    $(el).append('' +
        '<a href="https://v2inventory.davewilson.tech/v2/users/' + item_user_id + '" target="_blank"><h4>' + workerName +' (DWN#: '+dwn_id+', CVL#: '+cvlId+')</h4></a>' +
        //'<div>(' + dwn_id + ') ' + workerName + "(CVL#: " + cvlId + ")" +
            '<table class="tableCounts" id="dwn_id' + dwn_id + '" dwn_id="' + dwn_id + '" worker="' + workerName + '" cvl_id="' +cvlId + '">' +
                '<thead>' +
                    '<tr>' +
                        '<th>Date</th><th>Location</th><th>Description</th><th>Tied Quantity</th><th>Budded Quantity</th>' +
                    '</tr>' +
                '</thead>' +
                '<tbody></tbody>' +
            '</table>' +
        '</div>');

    if(per_page) {
        $(el).append('<div style="page-break-before: always;"></div>');
    }



    appendTable(worker_type,dwn_id,obj);
}

function appendTable(worker_type,dwn_id,o) {

    var date = o.bud_date;
    var location = o.block.name + ": " + o.block_row.row_number + "-" +  o.block_sub_row.sub_row_code;
    var description = o.description;
    if (worker_type == "budder"){
        var tiedQuantity = 0;
        var buddedQuantity = o.budded_quantity;
    } else {
        var tiedQuantity = o.budded_quantity;
        var buddedQuantity = 0;
    }

        var inventory_item_id = o.id;
    $('#dwn_id' + dwn_id + ' tbody').append('' +
        '<tr>' +
            '<td>' + moment(date).format('MM/DD/YYYY') + '</td>' +
            '<td><a href="https://v2inventory.davewilson.tech/v2/inventory/items?cols=budder_type,tier_type,seedling_quantity,block.name,block_row.row_number,block_sub_row.sub_row_code,branch_id,short_number,variety.name,type.name,root_stock.name,lotno_bud_used,bud_date,budded_quantity,budder.full_name,tier.full_name,print,actions,budded_by_user_id,tier_user_id&dateType=0&id=' + inventory_item_id + '" target="_blank">' + location + '</a></td>' +
            '<td>' + description + '</td>' +
            '<td class="tiedQty">' + tiedQuantity + '</td>' +
            '<td class="buddedQty">' + buddedQuantity + '</td>' +
        '</tr>'



    )
}
