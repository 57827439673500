/**
 * Created by DWN on 5/1/2016.
 */
app.controller('NewInventoryItemModalController', ['$scope', '$modalInstance', 'item', 'InventoryItems', 'Blocks',
    function($scope, $modalInstance, item, InventoryItems, Blocks) {
    console.log(item);
    $scope.item = item;

    $scope.selected = { };

    //plantyear and salesyear are not being assigned properly to item obj
    $scope.new = {
        item_number: item.short_number,
        root_stock: item['root_stock.name'],
        variety: item['variety.name'],
        type: item['type.name'],
        inventory_status_id: 1,
        quantity: 0,
        sales_year: item.sales_year || '2017',


        block: item['block.name'],
        block_row: item['block_row.row_number'],
        branch_id: item['branch.id'],
        plant_year: item.plant_year || '2015'
    };

    $scope.select = function() {
        console.log("selected in modal");
    }

    if(item['block_sub_row.sub_row_code'] == '0')
    {
        $scope.new.block_sub_row = 'b';
    }

    $scope.ok = function () {
        $modalInstance.close();
    };

    $scope.cancel = function () {
        $modalInstance.dismiss('cancel');
    };
    
    $scope.save = function($event) {

        $scope.new.block = $scope.selected.block.name;

        console.log($scope.new);

        if(!$scope.new.block_sub_row || $scope.new.block_sub_row.length < 1)
        {
            alert('Please enter a sub row');
        }
        else
        {
            $($event.target).prop("disabled", true);

            InventoryItems.create($scope.new).success(function(res) {
                $($event.target).prop("disabled", false);
                $scope.ok();
            }).error(function(res) {
                alert(res.message);
                $($event.target).prop("disabled", false);
            });
        }


    }
}]);