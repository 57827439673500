app.factory('BlockRows', ['$rootScope', '$http', function($rootScope, $http) {

    return {
        list: function(filters) {
            return $http.get('/blocks/rows', filters);
        },
        get: function(id) {
            return $http.get('/blocks/rows/'+id);
        },
        create: function(data) {

        }
    };
}]);