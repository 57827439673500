app.controller('BarcodeScanModalController', ['$scope', '$timeout','$modalInstance', '$document',
    function($scope, $timeout, $modalInstance, $document) {

    $scope.submit = function() {
        $modalInstance.close($scope.scan.barcode);
    };

    $scope.cancel = function() {
        $modalInstance.dismiss('cancel');
    };

    $timeout(function() {
        $timeout(function() {
            document.getElementById('scanBarcodeField').focus();
        })
    });

}]);