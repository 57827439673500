app.factory('DeliveryOrder', ['$rootScope', '$http', function($rootScope, $http) {

    return {
        updateStatus: function(orderId, statusId) {
            return $http.post('/deliveries/orders/'+orderId, {delivery_status_id: statusId});
        },
        delete: function(orderId) {
            return $http.delete('/deliveries/orders/'+orderId);
        },
        edit: function(orderId, payload) {
            return $http.post('/deliveries/orders/'+ orderId, payload);
        }
    };
}]);