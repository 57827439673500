app.factory('Timesheet', ['$rootScope', '$http', function($rootScope, $http) {

    return {
        list: function(filters) {
            return $http.get('/timesheets', filters);
        },
        get: function(id, params) {
            return $http.get('/timesheets/'+id, params)
        },
        create: function(data) {

        },
        search: function(params) {
            return $http.post('/timesheets', params);
        }
    };
}]);