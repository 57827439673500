app.factory('PestSurveys', ['$rootScope', '$http', '$httpParamSerializer',
    function($rootScope, $http, $httpParamSerializer) {

    return {
        list: function(params) {
            return $http.get('/surveys/pests', { params: params});
        },
        download: function(params)
        {
            return '/surveys/pests?'+$httpParamSerializer(params);
        },
        get: function(id) {

        },
        create: function(data) {

        },
        getByBlock: function(blockId, params) {
            return $http.get('/block/'+blockId+'/surveys', {params: params});
        }
    };
}]);