app.factory('RootStocks', ['$rootScope', '$http', function($rootScope, $http) {

    return {
        list: function(filters) {
            return $http.get('/root-stocks', filters);
        },
        get: function(id) {
            return $http.get('/root-stocks/'+id);
        },
        create: function(data) {

        }
    };
}]);