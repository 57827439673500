app.factory('BuddingTemp', ['$rootScope', '$http', function($rootScope, $http) {

    return {
        list: function(filters) {
            return $http.get('/budding_temps', {params:filters});
        },
        get: function(id) {
            return $http.get('/blocks/rows/'+id);
        },
        save: function(data) {
            return $http.post('/budding_temps', data);
        }
    };
}]);