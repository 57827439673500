function parse_barcode(barcode) {
    var hasControl = barcode[1];
    var control = null;

    if(barcode[0] == ':') {
        control = barcode[1];
    }
    else if(barcode[1] == ':') {
        control = barcode[0];
    }

    if(control)
        barcode = barcode.substring(2);

    var code = barcode.split(".");

    var location = {
        control: control,
        block: null,
        row: null,
        subRow: null
    };

    //Block
    if(code[0]) {
        location.block = code[0];
    }

    //Row
    if(code[1]) {
        location.row = parseInt(code[1]);
    }

    //Subrow
    if(code[2]) {
        location.subRow = code[2];
    }

    return location;
}

function fill_blocks_from_barcode(e, data, $scope) {
        console.log(data);
        if(data.location.block) {
            Blocks.byName(data.location.block).success(function(res){
                $scope.inventory_filters.block = res;
            }).error(function(res){
                alert('Invalid block.');
            });
        }

        if(data.location.row) {
            $scope.inventory_filters.block_row_start = data.location.row;
            $scope.inventory_filters.block_row_end = data.location.row;
        }

        if(data.location.subRow) {
            $scope.inventory_filters.subRowCode = data.location.subRow;
        }
}