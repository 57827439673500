angular.module('app.barcodeScan', []).directive('barcodeScan', ['$document', '$rootScope', '$modal', function($document, $rootScope, $modal){
    return {
        restrict: 'A',
        scope: {
            scan: '&',
            barcode: '='
        },
        link: function(scope, element, attr) {

            element.bind('click', function(e){
                var modal = $modal.open({
                    templateUrl: '/partials/modals/barcode/scan.html',
                    controller: 'BarcodeScanModalController as scan',
                    backdrop: 'static'
                });

                modal.result.then(function(res) {
                    scope.scan({barcode:res});
                });
            });

            scope.submit = function() {
                scope.scan({barcode:'test'});
            }
        }
    }
}]);