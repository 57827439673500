app.factory('Types', ['$rootScope', '$http', function($rootScope, $http) {

    return {
        list: function(filters) {
            return $http.get('/types', filters);
        },
        get: function(id) {

        },
        create: function(data) {

        }
    };
}]);