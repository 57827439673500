angular.module('app.barcodeListener', []).directive('barcodeListener', ['$document', '$rootScope', '$modal', function($document, $rootScope, $modal){
    return {
        restrict: 'A',
        scope: {
            type:'=',
            onScan:'&'
        },
        link: function(scope, element, attr) {
            console.log(attr, element);
            $rootScope.$on('barcodeShortcut.'+scope.type, function(e, data) {
                scope.onScan({data:data});
            });
        }
    }
}]);