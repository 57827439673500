
app.controller('TopBarCtrl', ['$scope', function($scope){

}]);

app.controller('DevHomeCtrl', ['$scope', 'links', function($scope, links){
    $scope.links = links.data;
}]);

app.controller('LoginCtrl', ['$scope', '$http', '$location', function($scope, $http, $location){
    "use strict";
    var path = $location.search();

    $scope.alerts = [{msg: path.message, type:'alert'}];

    $scope.login = function() {
        var payload = {
            email: $scope.email,
            password: $scope.password
        };

        $http.post('/login', payload).success(function(response) {
            if(path.redirectUrl)
            {
                try {
                    $location.path(path.redirectUrl).search(JSON.parse(localStorage.getItem('oldPath')));
                    localStorage.removeItem('oldPath');
                }
                catch(e)
                {
                    console.log(e);
                    //$location.path(path.redirectUrl).search({});
                }
            }
            else
            {
                $location.path('/timesheets/reports').search({});
            }

            window.location.reload();
        })
        .error(function(response){
            $scope.alerts = [{msg: response.message, type: 'alert'}];
        });
    }
}]);

app.controller('HomeCtrl', ['$scope', '$rootScope', 'InventoryItems', 'ngTableParams', 'WorkOrders', 'categories', 'team_leaders', 'Locations', 'PestSurveys', '$filter', 'Blocks',
    'Types', 'Varieties', 'RootStocks', 'Branches', '$location', 'links',
function($scope, $rootScope, InventoryItems, ngTableParams, WorkOrders, categories, team_leaders, Locations, PestSurveys, $filter, Blocks,
         Types, Varieties, RootStocks, Branches, $location, links) {
    "use strict";
    /*
    try {
        $scope.inventory_filters = JSON.parse($location.search().inventory_filters);
    }
    catch(e)
    {
        $scope.inventory_filters = {};
        $scope.inventory_filters.group_by = [];
    }

    try {
        var hidden = $location.search().hide;

        $scope.hidden = hidden.split(",");

        console.log($scope.hidden.indexOf("block"));
    }
    catch(e)
    {

    }
    */

    console.log(links);
    $scope.links = links;

    $scope.blocks = {};
    $scope.refreshBlocks = function(block, filters, scope) {

        var params = {
            block: block
        };

        if(!filters)
            params.inventory_filters = $scope.inventory_filters;

        return Blocks.list({params: params})
            .success(function(res) {

                if(scope)
                    $scope[scope] = res;
                else
                    $scope.blocks = res;
        })
    };

    $scope.branches = {};
    $scope.refreshBranches = function(branch, filters, scope) {
        var params = {
            branch: branch
        };

        if(!filters)
            params.inventory_filters = $scope.inventory_filters;

        return Branches.list({params: params})
            .success(function(res) {

                if(scope)
                    $scope[scope] = res;
                else
                    $scope.branches = res;
            })
    };

    $scope.types = {};
    $scope.refreshTypes = function(type, filters, scope) {

        var params = {
            name: type
        };

        if(!filters)
            params.inventory_filters = $scope.inventory_filters;

        return Types.list({params: params})
            .success(function(res){

                if(scope)
                    $scope[scope] = res;
                else
                    $scope.types = res;
        });
    };

    $scope.varieties = {};
    $scope.refreshVarieties = function(variety, filters, scope) {

        var params = {
            name: variety
        };

        if(!filters)
            params.inventory_filters = $scope.inventory_filters;

        return Varieties.list({params: params})
            .success(function(res){

                if(scope)
                    $scope[scope] = res;
                else
                    $scope.varieties = res;
            });
    };

    $scope.root_stocks = {};
    $scope.refreshRootStocks = function(root_stock, filters, scope) {

        var params = {
            name: root_stock
        };

        if(!filters)
            params.inventory_filters = $scope.inventory_filters;

        return RootStocks.list({params: params})
            .success(function(res){
                if(scope)
                    $scope[scope] = res;
                else
                    $scope.root_stocks = res;
            });
    };

    $scope.sales_years = {};
    $scope.refreshSalesYears = function(sales_year, filters, scope) {

        var params = {
            year: sales_year
        };

        if(!filters)
            params.inventory_filters = $scope.inventory_filters;

        return InventoryItems.salesYears({params: params})
            .success(function(res){

                if(scope)
                    $scope[scope] = res;
                else
                    $scope.sales_years = res;
            });
    };

    $scope.refreshAll = function() {
        $scope.refreshBlocks();
        $scope.refreshTypes();
        $scope.refreshVarieties();
        $scope.refreshRootStocks();
        $scope.refreshSalesYears();
        $scope.refreshBranches();
    };

    $scope.group_by = [
        {name: 'Description', id: 'inventory_items.description'},
        {name: 'Block', id: 'inventory_items.block_id'},
        {name: 'Block Row', id: 'inventory_items.block_row_id'},
        {name: 'Sub Row', id: 'inventory_items.block_sub_row_id'},
        {name: 'Variety', id: 'inventory_items.variety_id'},
        {name: 'Sales Year', id: 'inventory_items.sales_year'},
        {name: 'Root Stock', id: 'inventory_items.root_stock_id'},
        {name: 'Type', id:'inventory_items.type_id'},
        {name: 'Inventory Number', id: 'inventory_number'},
        {name: 'Inventory Status', id:'inventory_items.inventory_status_id'},
        {name: 'Inventory Type', id: 'inventory_items.inventory_type_id'},
        {name: 'User',  id: 'inventory_items.user_id'},
        {name: 'Branch', id:'inventory_items.branch_id'}
    ];

    $scope.columns = [
        {title: 'id', field: 'inventory_item_id', selected: true, sortable: 'inventory_item_id'},
        {title: 'Qty', field: 'quantity', selected: true, sortable: 'quantity'},
        {title: 'Bud Qty', field:'budded_quantity', selected: true, sortable: 'budded_quantity'},
        {title: 'Location', field: 'block.location.name', selected: true, sortable: 'locations.name'},
        {title: 'Block', field: 'block.name', selected: true, sortable: 'blocks.name', groupedField: 'grouped_blocks'},
        {title: 'Block Row', field: 'block_row.row_number', selected: true, sortable: 'block_rows.row_number', groupedField: 'grouped_rows'},
        {title: 'Block Sub Row', field: 'block_sub_row.sub_row_code', selected:true, sortable: 'block_sub_rows.sub_row_code', groupedField: 'grouped_subrows'},
        {title: 'Description', field: 'description', selected: true, sortable: 'inventory_items.description', groupedField: 'grouped_descriptions'},
        {title: 'Variety', field: 'variety.name', selected: true, sortable: 'varieties.name', groupedField: 'grouped_varieties'},
        {title: 'Sales Year', field: 'sales_year', selected: true, sortable: 'inventory_items.sales_year'},
        {title: 'Bud Source', field: 'bud_source.name', selected: false, sortable: 'bud_sources.name'},
        {title: 'Root Stock', field: 'root_stock.name', selected: true, sortable: 'root_stocks.name', groupedField: 'grouped_root_stocks'},
        {title: 'Type', field:'type.name', selected: true, sortable: 'types.name', groupedField: 'grouped_types'},
        {title: 'Prop Type', field: 'propagation_type.name', selected: false, sortable: 'propagation_types.name'},
        {title: 'Inventory Type', field: 'inventory_type.name', selected: false, sortable:'inventory_types.name'},
        {title: 'Status', field: 'inventory_status.name', selected: false, sortable: 'inventory_statuses.name'},
        {title: 'Inventory Number', field: 'inventory_number', selected: true, sortable: 'inventory_items.inventory_number'},
        {title: 'Branch', field:'branch.name', selected: true, sortable: 'branches.name'},
        {title: 'User', field:'user.full_name', selected:true, sortable: 'inventory_items.user_id'}
    ];

    /*
    try {
        $scope.columns.selected = JSON.parse($location.search().columns);
    }
    catch(e)
    {
        $scope.columns.selected = $scope.columns;
    }
    */

    $scope.getValue = function(item, col) {

        if(typeof item[col.groupedField] != "undefined")
        {
            return item[col.groupedField] || '';
        }
        else
        {
            var field = col.field;
        }
        var keys = field.split(".");

        var result = item;

        if(keys.length == 1)
        {
            return (item[field] || '');
        }
        else
        {
            var i = 0;
            while(typeof result == "object")
            {
                var key = keys[i];

                if(result[key] == null)
                {
                    result = '';
                    break;
                }

                result = result[key];

                i++;
            }

            return result;
       }

        return '';
    };

    $scope.categories = categories;
    $scope.categories.selected = {};


    $scope.survey_location = '';
    $scope.survey_blocks = {};
    $scope.survey_blocks.selected = {};

    $scope.survey = {
        block: {}
    };

    $scope.start_date = '';
    $scope.end_date = '';

    $scope.latest_blocks = false;

    $scope.inventoryTable = new ngTableParams({
        page: 1,
        count: 15,
        sorting: {
            'inventory_items.id': 'desc'
        }
    }, {
        getData: function($defer, params) {

            var param = {};
            if($scope.inventory_filters.block)
                param.blockId = $scope.inventory_filters.block.id;

            if($scope.inventory_filters.type)
                param.typeId = $scope.inventory_filters.type.id;

            if($scope.inventory_filters.variety)
                param.varietyId = $scope.inventory_filters.variety.id;

            if($scope.inventory_filters.root_stock)
                param.rootStockId = $scope.inventory_filters.root_stock.id;

            if($scope.inventory_filters.inventory_number)
                param.inventory_number = $scope.inventory_filters.inventory_number;

            if($scope.inventory_filters.sales_year)
                param.salesYear = $scope.inventory_filters.sales_year.sales_year;

            if($scope.inventory_filters.branch)
                param.branchId = $scope.inventory_filters.branch.id;

            if($scope.inventory_filters.group_by)
            {
                param.groupBy = $scope.inventory_filters.group_by;
            }

            if($scope.inventory_filters.block_row_start)
            {
                param.blockRowStart = $scope.inventory_filters.block_row_start;
            }

            if($scope.inventory_filters.block_row_end)
            {
                param.blockRowEnd = $scope.inventory_filters.block_row_end;
            }

            if($scope.inventory_filters.start_date)
            {
                param.start_date = $scope.inventory_filters.start_date;
            }

            if($scope.inventory_filters.end_date)
            {
                param.end_date = $scope.inventory_filters.end_date;
            }

            if($scope.inventory_filters.date_type)
            {
                param.date_type = $scope.inventory_filters.date_type;
            }

            console.log(param);

            param.page = params.page();
            param.per_page = params.count();
            param.orderBy = params.orderBy();

            InventoryItems.list(param)
            .success(function(data) {
                params.total(data.items.total);
                $defer.resolve(data.items.data);
                $scope.inventoryItems = data;
            });
        }
    });

    $scope.exportInventoryExcel = function() {
        var param = {};

        if($scope.inventory_filters.block)
            param.blockId = $scope.inventory_filters.block;

        if($scope.inventory_filters.type)
            param.typeId = $scope.inventory_filters.type;

        return InventoryItems.download(param);
    };

    $scope.tasksTable = new ngTableParams({
        page: 1,
        count: 15,
        sorting: {
            est_start_date: 'desc'
        }
    }, {
        getData: function($defer, params) {

            var param = {};
            param.page = params.page();


            if($scope.task_blocks && $scope.task_blocks.selected)
                param.blockId = $scope.task_blocks.selected.id;

            if($scope.categories && $scope.categories.selected)
                param.categoryId = $scope.categories.selected.id;

            param.per_page = params.count();
            param.orderBy = params.orderBy();

            WorkOrders.list(param)
                .success(function(data) {

                    params.total(data.total);
                    $defer.resolve(data.data);
                    $scope.workOrders = data;

                });
        }
    });

    $scope.surveyTable = new ngTableParams({
        page: 1,
        count: 15,
        sorting: {
            created_at: 'desc'
        }
    }, {
        getData: function($defer, params) {

            var param = {};
            param.page = params.page();

            if($scope.survey.start_date)
            {
                param.start_date = new Date($scope.survey.start_date);
                param.start_date.setHours(0,0,0);
                param.start_date = param.start_date.toISOString();
            }

            if($scope.survey.end_date)
            {
                param.end_date = (new Date($scope.survey.end_date));
                param.end_date.setHours(23, 59, 59);
                param.end_date = param.end_date.toISOString();
            }

            if($scope.survey.block)
            {
                param.blockId = $scope.survey.block.id;
            }

            param.per_page = params.count();
            param.orderBy = params.orderBy();

            PestSurveys.list(param)
                .success(function(data) {
                    params.total(data.total);

                    $defer.resolve(data.data);

                    $scope.surveys = data;
                });
        }
    });

    $scope.exportExcel = function() {
        var param = {};
        if($scope.survey.start_date)
        {
            param.start_date = new Date($scope.survey.start_date);
            param.start_date.setHours(0,0,0);
            param.start_date = param.start_date.toISOString();
        }

        if($scope.survey.end_date)
        {
            param.end_date = (new Date($scope.survey.end_date));
            param.end_date.setHours(23, 59, 59);
            param.end_date = param.end_date.toISOString();
        }

        if($scope.survey.block.id)
        {
            param.blockId = $scope.survey.block.id;
        }

        param.excel = "true";

       return PestSurveys.download(param);
    };


    $scope.$watch('inventory_filters', function(newValue, oldValue) {

        if(newValue.block)
            $scope.inventoryTable.$params.sorting = { 'block_rows.row_number' : 'asc'};

        $scope.inventoryTable.$params.page = 1;
        $scope.inventoryTable.reload();

        //$location.search('inventory_filters', JSON.stringify($scope.inventory_filters));

        $scope.refreshAll();

    }, true);

    $scope.$watch('columns.selected', function(newValue, oldValue){
       $location.search('columns', JSON.stringify($scope.columns.selected));
    });


    $scope.$watch('task_blocks.selected', function(newValue, oldValue){
        $scope.tasksTable.reload();
    });

    $scope.$watch('latest_blocks', function(value, oldValue){

        if(value != oldValue)
        {
            $scope.blocks = value;
        }
    });

    $scope.$watch('latest_types', function(value, oldValue){
        if(value != oldValue)
        {
            $scope.types = value;
        }
    });

    $scope.$watch('task_location', function(value) {
        $scope.tasksTable.reload();
        $scope.tasksTable.$params.page = 1;
    });
    $scope.$watch('categories.selected', function(value) {
        $scope.tasksTable.reload();
        $scope.tasksTable.$params.page = 1;
    });

    $scope.$watch('users.selected', function(value){
        $scope.tasksTable.reload();
        $scope.tasksTable.$params.page = 1;
    });

    $scope.$watch('survey.block', function(value){
        $scope.surveyTable.$params.page = 1;
        $scope.surveyTable.reload();
    });

    $scope.$watch("survey.end_date", function(value){
        $scope.surveyTable.$params.page = 1;
        $scope.surveyTable.reload();
    });

    $scope.$watch("survey.start_date", function(value){
        $scope.surveyTable.$params.page = 1;
        $scope.surveyTable.reload();
    });

    $scope.clear = function(val) {
        var split = val.split(".");

        if(split[1])
            $scope[split[0]][split[1]] = undefined;
        else
            $scope[val] = {};
    };

    $scope.getLocation = function(query) {
        return Locations.list({name: query}).then(function(r){
            return r.data;
        });
    }



}]);