app.controller('ProductSearchController', ['$scope', 'ngTableParams', '$modalInstance', 'Products', 'Varieties', 'Types', '$sce',
    function($scope, ngTableParams, $modalInstance, Products, Varieties, Types, $sce) {

    /*
        $scope.varieties = varieties.data;
        $scope.root_stocks = root_stocks.data;
        $scope.types = types.data;
        $scope.product_types = Products.productTypes();
        */
        $scope.search = {};
        $scope.selected = {};


        $scope.productsTable = new ngTableParams({
            page: 1,
            count: 5
        }, {
            counts:0,
            getData: function($defer, params) {
                var payload = {};

                $scope.search.page = params.page();
                $scope.search.per_page = params.count();
                $scope.search.with_dropdowns = true;

                Products.list($scope.search).success(function(data) {
                    _.each(data.data, function(item, index) {
                        try {
                            console.log(data.data);
                            data.data[index].color_code.color_code_HTML = $sce.trustAsHtml(item.color_code.color_code_HTML);
                        }
                        catch(e) {
                            console.log(e);
                        }
                    });
                    params.total(data.total);
                    $defer.resolve(data.data);
                    $scope.types = data.types;
                    $scope.varieties = data.varieties;
                    $scope.root_stocks = data.root_stocks;
                    $scope.product_types = data.product_types;

                }).error(function(res) {
                    params.total(0);
                    $defer.resolve([{
                        description:'No items found.'
                    }]);
                })
            }
        });

        $scope.select = function(test) {
            $scope.productsTable.reload();
        };

        $scope.cancel = function() {
            $modalInstance.dismiss('cancel');
        };

        $scope.submit = function() {
            $modalInstance.close($scope.selected);
        };

        $scope.reset = function() {
            $scope.search = {};
            $scope.select();
        };

        $scope.selectItem = function($event, product) {
            angular.element('.selectRow').removeClass('selected');
            angular.element($event.currentTarget).addClass('selected');
            $scope.selected = product;
        };

        $scope.varieties = [];
        $scope.refreshVarieties = function(variety, filters, scope) {

            var params = {
                name: variety,
                limit:50
            };

            return Varieties.list({params: params})
                .success(function(res) {
                        $scope.varieties = res;
                });
        };
}]);