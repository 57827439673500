app.factory('Varieties', ['$rootScope', '$http', function($rootScope, $http) {

    return {
        list: function(filters) {
            return $http.get('/varieties', filters);
        },
        get: function(id) {
            return $http.get('/varieties/'+id);
        },
        create: function(data) {

        }
    };
}]);