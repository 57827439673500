app.factory('Shipment', ['$rootScope', '$http', function($rootScope, $http) {

    return {
        list: function(filters) {
            return $http.get('/shipments', {params: filters});
        },
        get: function(id) {
            return $http.get('/shipments/'+id);
        },
        create: function(data) {
            return $http.post('/shipments', data);
        },
        update: function(id, data) {

            console.log(id);

            if(typeof id == 'object') {
                data.ids = id;
                return $http.post('/shipments/bulk', data);
            }
            else
                return $http.post('/shipments/'+id, data);
        },
        transfer: function(data) {
            return $http.post('/shipments/transfer', data);
        }
    };
}]);