app.controller('DeliveriesController', ['$scope', 'deliveries', 'delivery_statuses', 'ngTableParams', 'Delivery', 'colors',
    function($scope, deliveries, delivery_statuses, ngTableParams, Delivery, colors) {

        $scope.delivery_statuses = delivery_statuses.data;
        $scope.deliveries = deliveries;
        $scope.colors = colors;

        $scope.reset = function() {
            $scope.delivery_statuses.selected = null;
        };

        $scope.itemsTable = new ngTableParams({
            page: 1,
            count: 15
        }, {
            getData: function($defer, params) {

                var p = {};
                p.page = params.page();

                if($scope.delivery_statuses.selected)
                    p.delivery_status_id = $scope.delivery_statuses.selected.id;

                Delivery.list(p).success(function(data){
                    params.total(data.total);
                    $defer.resolve(data.data);
                });
            }
        });

    $scope.$watch('delivery_statuses.selected', function(newValue, oldValue){
        $scope.itemsTable.reload();
    });
}]);
app.controller('ViewDeliveryController', ['$scope', 'Orders', 'delivery', '$q', '$modal', '$window', 'delivery_statuses', 'DeliveryOrder', 'Delivery', '$location', '$state', 'colors',
function($scope, Orders, delivery, $q, $modal, $window, delivery_statuses, DeliveryOrder, Delivery, $location, $state, colors) {

    console.log(colors);
    $scope.delivery = delivery;
    $scope.colors = colors.data;
    $scope.delivery_statuses = delivery_statuses.data;

    $scope.select_status = function(order, index, item) {
        order.delivery_status_id = item.id;

        DeliveryOrder.updateStatus(order.id, item.id)
            .success(function(response){
                $state.reload();
            })
            .error(function(response){
                $state.reload();
            });
    };

    $scope.select_color = function(order, index, item) {
        order.color = item.slug;

        if(prompt("Enter Password") === "dwn") {
            DeliveryOrder.edit(order.id, {
                    color: item.slug
                })
                .success(function (response) {
                    $state.reload();
                })
                .error(function (response) {
                    $state.reload();
                });
        }
    };

    $scope.line_items = function(sop_number) {
        var modalInstance = $modal.open({
            templateUrl: '/partials/modals/orders/static_order.html',
            controller: 'OrderLineItemsModalController',
            resolve: {
                items: function () {
                    var defer = $q.defer();
                    Orders.searchItems({sop_number: sop_number})
                        .success(defer.resolve)
                        .error(defer.reject);
                    return defer.promise;
                },
                order: function() {
                    var defer = $q.defer();
                    Orders.search({sop_number: sop_number})
                        .success(defer.resolve)
                        .error(defer.reject);
                    return defer.promise;
                }
            }
        });
    };

    $scope.print = function(id) {
        $window.open('/deliveries/'+id+'/print', "print_delivery", "height=600,width=800");
    }

    $scope.delete = function(delivery) {
        if(prompt("Enter Delete Password") === "dwn")
        {
            Delivery.delete(delivery.id).success(function(res){
                $location.path('/deliveries');
            });
        }
    }

    $scope.remove_order = function(order) {
        if(prompt("Enter Delete Password") === "dwn")
        {
            DeliveryOrder.delete(order.id).success(function(res){
                $state.reload();
            });
        }
    };

    $scope.add = function(sop_number, blank) {

        var color = $scope.colors[(delivery.delivery_order.length-1)%6];

        Delivery.addOrder(delivery.id, sop_number, color, blank).success(function(res){
            $state.reload();
        })
            .error(function(res){
                var msg = [];

                $.each(res, function(index, msg){
                    alert(msg);
                });
            });
    };

    $scope.change_sop = function(order) {
        var sop_number = prompt("Enter new SOP Number");

        DeliveryOrder.edit(order.id, {sop_number: sop_number})
            .success(function(res){
                $state.reload();
            })
            .error(function(res) {
                $.each(res, function(index, msg){
                    alert(msg);
                });
            });
    }
}]);
app.controller('DeliverySchedulerController', ['$scope', 'Orders', 'drivers', '$http', '$modal', '$q', '$location',
    function($scope, Orders, drivers, $http, $modal, $q, $location) {
    $scope.order = false;
    $scope.orders = [];
    $scope.colors = ['white','yellow','red','green','blue','orange'];
    $scope.loadTypes = ['BOB','Single','Double','53','CC','UPS','LTL','FMF','Customer Pickup'];
    $scope.loadTypes.selected = [];
    $scope.drivers = drivers;

        var colorIndex = 0;

    $scope.add = function(sop_number) {
        if($scope.orders.length >= 20) return false;

        var split = sop_number.split(" ");

        var i = 0;

        function getSOP(split) {
            var sop = split[i];
            Orders.search({sop_number: sop})
                .success(function(response){
                    if(response.data.length > 0) {
                        var order = response.data[0];

                        order.color = $scope.colors[colorIndex%6];
                        $scope.orders.push(response.data[0]);
                        $scope.sop_number = '';
                        colorIndex++;

                        i++;
                        if(i<split.length)
                        {
                            getSOP(split);
                        }
                    }
                    else
                    {
                        alert('No Order Found for: '+sop+'.');
                    }
                })
                .error(function(response){
                    alert('No Order Found for: '+sop+'.');
                    i++;
                });
        }

        getSOP(split);

    };

    $scope.blank_line = function() {
        $scope.orders.push({sop_number:'blank', color: false});
    };

    $scope.submit = function() {
        var sop_numbers = [];

        console.log($scope.orders);

        angular.forEach($scope.orders, function(order, index){
            sop_numbers.push(order.sop_number);
        });

        var data = {
            sop_numbers: sop_numbers,
            orders: $scope.orders,
            driver_id: $scope.drivers.selected.id,
            load_type: $scope.loadTypes.selected,
            load_number: $scope.load_number
        };

        $http.post('/deliveries', data)
            .success(function(response){
                $location.path('/deliveries/'+response.id);
            }).
            error(function(response){
                alert(response.error);
        });
    };

    $scope.line_items = function(sop_number) {
        var modalInstance = $modal.open({
            templateUrl: '/partials/modals/orders/order.html',
            controller: 'OrderLineItemsModalController',
            resolve: {
                items: function () {
                    var defer = $q.defer();
                    Orders.searchItems({sop_number: sop_number})
                        .success(defer.resolve)
                        .error(defer.reject);
                    return defer.promise;
                },
                order: function() {
                    var defer = $q.defer();
                    Orders.search({sop_number: sop_number})
                        .success(defer.resolve)
                        .error(defer.reject);
                    return defer.promise;
                }
            }
        });
    }



}]);
app.controller('OrderLineItemsModalController', ['$scope', '$modalInstance', 'order', 'items', 'ngTableParams', 'Orders',
    function($scope, $modalInstance, order, items, ngTableParams, Orders) {
    $scope.items = items.data;
    $scope.order = order.data[0];

    $scope.pull = function(item) {
        Orders.pullLineItem(item.id)
            .success(function(response){
                $scope.itemsTable.reload();
            });
    };

        $scope.itemsTable = new ngTableParams({
            page: 1,
            count: 15
        }, {
            getData: function($defer, params) {



                Orders.searchItems({sop_number: $scope.order.sop_number, page: params.page()})
                    .success(function(data){
                        params.total(data.total);
                        $defer.resolve(data.data);
                        $scope.items = data;
                    });

            }
        });
}]);
