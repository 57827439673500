app.config(['$stateProvider', '$urlRouterProvider', function($stateProvider, $urlRouterProvider) {
    $urlRouterProvider.otherwise('/');

    $stateProvider
        .state('home', {
            url: '/',
            templateUrl: 'partials/home.html',
            controller: 'HomeCtrl',
            resolve: {
                categories: ['WorkOrderCategories', '$q', function(WorkOrderCategories, $q){
                    var defer = $q.defer();
                    WorkOrderCategories.list().success(defer.resolve).error(defer.reject);
                    return defer.promise;
                }],
                team_leaders: ['Users', '$q', function(Users, $q){
                    var defer = $q.defer();
                    Users.list().success(defer.resolve).error(defer.reject);
                    return defer.promise;
                }],
                links: ['Link', '$q', function(Link, $q) {
                    var defer = $q.defer();
                    Link.list().success(defer.resolve).error(defer.reject);
                    return defer.promise;
                }]
            }
        })
        .state('dev-home', {
            url:'/home',
            templateUrl: 'partials/dev-home.html',
            controller: 'DevHomeCtrl',
            resolve: {
                links: ['Link', function(Link) {
                    return Link.list({page:'home'});
                }]
            }
        })
        .state('barcode_scan', {
            url:'/barcode/scan',
            templateUrl: 'partials/barcode/scan.html',
            controller: 'BarcodeScanController'
        })
        .state('delivery_scheduler', {
            url:'/deliveries/schedule',
            templateUrl: 'partials/deliveries/schedule.html',
            controller: 'DeliverySchedulerController',
            resolve: {
                drivers: ['$http', '$q', function($http, $q){
                    var defer = $q.defer();
                     $http.get('/users/position/1')
                         .success(defer.resolve)
                         .error(defer.reject);
                    return defer.promise;
                }]
            }
        })
        .state('product-finder', {
            url:'/products/search',
            templateUrl: 'partials/products/search.html',
            controller: 'ProductSearchController',
            resolve: {
                varieties: ['Varieties', function(Varieties) {
                    return Varieties.list();
                }],
                root_stocks: ['RootStocks', function(RootStocks){
                    return RootStocks.list();
                }],
                types: ['Types', function(Types) {
                    return Types.list();
                }]
            }
        })
        .state('grading-counts', {
            url:'/grading/counts',
            templateUrl:'partials/grading/counts.html',
            controller:'GradingCountsController',
            resolve:{
                sizes: ['Products', function(Products){
                    return Products.calipers();
                }],
                branches: ['Branches', function(Branches) {
                    return Branches.list();
                }]
            }
        })
        .state('delivery_view', {
            url:'/deliveries/:deliveryId',
            templateUrl:'partials/deliveries/view.html',
            controller: 'ViewDeliveryController',
            resolve: {
                delivery: ['$http', '$q', '$stateParams', function($http, $q, $stateParams){
                    var defer = $q.defer();

                    $http.get('/deliveries/'+$stateParams.deliveryId)
                        .success(defer.resolve)
                        .error(defer.reject);

                    return defer.promise;
                }],
                delivery_statuses: ['$http', '$q', function($http, $q){
                    return $http.get('/deliveries/statuses');
                }],
                colors: ['$http', function($http){
                    return $http.get('/colors');
                }]
            }
        })
        .state('deliveries', {
            url: '/deliveries',
            templateUrl: 'partials/deliveries/index.html',
            controller: 'DeliveriesController',
            resolve: {
                deliveries: ['$http', '$q', function($http, $q){
                    return $http.get('/deliveries');
                }],
                delivery_statuses: ['$http', function($http) {
                    return $http.get('/deliveries/statuses');
                }],
                colors: ['$http', function($http){
                    return $http.get('/colors');
                }]
            }
        })
        .state('recount', {
            url:'/inventory/recount',
            templateUrl: 'partials/inventory/recount.html',
            controller: 'InventoryRecountController'
        })
        .state('inventory', {
            url:'/inventory',
            templateUrl: 'partials/inventory.html',
            controller: 'InventoryListCtrl'
        })
        .state('inventory_bulk_edit', {
            url:'/inventory/bulk',
            templateUrl: '/partials/inventory/bulk-edit.html',
            controller: 'InventoryListCtrl'
        })
        .state('tasks', {
            url: '/tasks',
            templateUrl: 'partials/tasks.html'
        })
        .state('pest-survey', {
            url:'/block/:blockId/surveys',
            templateUrl: 'partials/surveys/pests/view.html',
            controller: 'ViewSurveyByBlockCtrl',
            resolve: {
                block: ['$q', 'Blocks', '$stateParams', function($q, Blocks, $stateParams){
                    return Blocks.get($stateParams.blockId);
                }]
            }
        })
        .state('timesheet-reports', {
            url: '/timesheets/reports',
            templateUrl: 'partials/timesheets/reports.html',
            controller: 'TimesheetReportController',
            resolve: {
                team: function() { return false; }
            }
        })
        .state('timesheet-reports-team', {
            url: '/timesheets/reports/:teamId',
            templateUrl:'/partials/timesheets/reports.html',
            controller: 'TimesheetReportController',
            resolve: {
                team: ['$stateParams', 'Team', function($stateParams, Team){
                    return Team.get($stateParams.teamId);
                }]
            }
        })
        .state('shipments', {
            url:'/shipments',
            templateUrl:'/partials/shipments/index.html',
            controller:'ShipmentCtrl',
            resolve: {
                shipments: ['Shipment', '$location', function(Shipment, $location){
                    return Shipment.list({status: 'ARCHIVE',orderBy:"-updated_at"});
                }]
            }
        })//
        .state('shipments_transfer', {
            url: '/shipments/{shipmentId}/transfer',
            templateUrl: '/partials/shipments/transfer.html',
            controller: 'TransferShipmentCtrl',
            resolve: { 
                shipment: ['Shipment', '$stateParams', function(Shipment, $stateParams){
                    return Shipment.get($stateParams.shipmentId);
                }]
            }
        })
        .state('create_shipment',{
            url:'/shipments/create',
            templateUrl:'/partials/shipments/create.html',
            controller: 'CreateShipmentCtrl',
            resolve: {
                containerTypes: ['ContainerType', function(ContainerType){
                    return ContainerType.list();
                }],
                shipments: ['Shipment', function(Shipment){
                    return Shipment.list({orderBy:'-created_at'});
                }]
            }
        })
        .state('revisions', {
            url: '/revisions',
            templateUrl: '/partials/revisions/view.html',
            controller: 'RevisionController'
        })
        .state('login', {
            url: '/login',
            templateUrl: '/partials/users/login.html',
            controller: 'LoginCtrl'
        })
        .state('fertilizing_report',{
            url: '/fertilizing/reports',
            templateUrl: '/partials/fertilizing/index.html',
            controller: 'FertilizingReportsController',
            resolve: {
                reports: ['$stateParams', 'WorkOrders', function($stateParams, WorkOrders){
                    return WorkOrders.list({categoryId: 201});
                }]
            }
        })
        .state('fertilizing_report_new',{
            url: '/fertilizing/reports/new',
            templateUrl: '/partials/fertilizing/new.html',
            controller: 'NewFertilizingReportController'
        })
        .state('piecework_budding_new', {
            url:'/piecework/budding/new',
            templateUrl: '/partials/piecework/budding/new.html',
            controller: 'NewBuddingPieceworkController'
        })
        .state('piecework_budding_restperiods', {
            url:'/piecework/budding/restperiods',
            templateUrl: '/partials/piecework/budding/restperiods.html',
            controller: 'NewBuddingPieceworkController'
        })
        .state('piecework_budding_counts', {
            url:'/piecework/budding/counts',
            templateUrl: '/partials/piecework/budding/counts.html',
            controller: 'NewBuddingPieceworkController'
        })
        .state('blocks_history', {
            url:'/blocks/history',
            templateUrl: 'partials/blocks/history.html',
            controller: 'blocksHistoryReportController',
            resolve: {
                restructure: ['$http', function($http){
                    return $http.get('/restructure-surveys');
                }]
            }
        })
}]);