app.factory('Team', ['$rootScope', '$http', function($rootScope, $http) {

    return {
        list: function(filters) {
            return $http.get('/teams', filters);
        },
        get: function(id, filters) {
            return $http.get('/teams/'+id, filters);
        },
        create: function(data) {

        }
    };
}]);