/**
 * Created by Main on 5/31/2016.
 */
app.controller('blocksHistoryReportController', ['$scope', 'WorkOrders', 'Team', 'BuddingTemp', '$q', '$timeout', 'InventoryItems', 'ngTableParams', 'Users',
    function($scope, WorkOrders, Team, BuddingTemp, $q, $timeout, InventoryItems, ngTableParams, Users) {

        var blocksArr = "E1,OT-1,OT-2,OT-3,OT-4,OT-5,OT-6,OT-7,OP-1,OP-2,GH-1,GH-2,GH-3,GH-4,GH-5,GH-6," +
            "GH-7,A1,A1B,A2,H2,H3,A2A,A2,A1A,A2B,A3,B2,B3,C1,C2,H2A,H2C,H7,A1A,D1A,M2,H1A,H5,H6,H8,R1A,R1B,R1D,R2A," +
            "R2B,R2C,R2DA,R3A1,R2B,D1B,B5,B4,R3B,R1A,A2,B3,C1,C2,H5,A2B,H6,M1,M2,POT,A1C,OB1,B2,A5,R3E,R4A,R3D,R4D,R3C," +
            "B5A,B5B,B2,E1,D2,R3A,R3B,R1C,R4B,R3F,R4C";

        var blocksA = blocksArr.split(',');
        var tables =
            [
                {
                    title: 'JUNEBUDS Lake Rd & Hooker Ranch',
                    blocks: ['A2','A3','H7','M2'],
                    data: []
                },
                {
                    title: 'YEARLINGS Lake Rd & Machado Ranch',
                    blocks:['B2','H3','E1','D2','A1'],
                    data: []
                },
                {
                    title: 'JUNEBUDS Red Rock Rd',
                    blocks:['R1B','R1D','R2C','R3A','R3B','R3D'],
                    data: []
                },
                {
                    title: 'WALNUTS Hooker Ranch & Red Rock',
                    blocks: ['R1C', 'R4B', 'R3F', 'R4C'],
                    data:[]
                }
            ];

        var used = [];
        _.each(tables, function(e) {
            e.blocks.sort();
            _.each(e.blocks, function(i) {
                used.push(i);
            });
        });

        var last = _.unique(_.difference(blocksA, used));
        var allBlocks = _.unique(blocksA.concat(used));

        tables.push({title: 'Potted Division Lake Rd', blocks: last.sort(), data:[]});
        $scope.tables = tables;

        console.log($scope.tables);

        var workOrdersByBlock = {};

        $scope.start_time = moment().subtract(14, 'days').toDate();
        $scope.end_time = moment().add(0, 'days').toDate();

        var loaded = false;

       $scope.update = function() {

            var start = moment($scope.start_time).startOf('day');
            var end = moment($scope.end_time).endOf("day");

            var diff = end.diff(start, 'days');
            $scope.columns = [];

            for (var i = 0; i <= diff; i++) {
                $scope.columns.push({
                    name: moment(start).add(i, 'days').format('ddd'),
                    date: moment(start).add(i, 'days').format('Do'),
                    julain: moment(start).add(i, 'days').format('DDD'),
                    id: moment(start).add(i, 'days').format("YYYY-MM-DD")
                });
            }
//
            //console.log( $scope.blocksTable);
            //console.log('thisHereBlock:',$scope.block);


//

                    var param = {};
                    // param.categoryId = 928;
                    param.per_page = 555;
                    param.group = true;
                    param.start_est_date = moment($scope.start_time).format('YYYY-MM-DD');
                    param.categoryIds = "928,929,930";
                    param.blockNamesArr = allBlocks.join(",");
                    //  param.order_by = "+block.name";
                    param.end_est_date = moment($scope.end_time).format('YYYY-MM-DD');
            if($scope.block)
                    param.blockName = $scope.block;
            //param.blockId = 238;
                    //console.log(param);
                    /*
                     $.ajax({
                     type: "GET",
                     dataType: "json",
                     url: "http://dwn.tilvid.com/surveys/pests?start_date=" + param.start_date + "&end_date=" + param.end_date + "&per_page=" + param.per_page,
                     success: function(response){
                     $scope.pestData = response.data;
                     console.log($scope.pestData);
                     }});
                     */
                    WorkOrders.list(param)
                        .success(function (data) {
                            workOrdersByBlock = parseWorkOrders(data);
                            console.log(workOrdersByBlock);
                            //console.log('here',data);
                            //params.total(data.items.total);
                         //   $defer.resolve(data);
                            //console.log('BEFORE!!,',data);




                            var blocksWOArr = [];

                            _.each(data.blocks,function(item){
                                blocksWOArr.push(item);
                            });


                            blocksWOArr = _.sortBy(blocksWOArr,function(item){
                                return item.block.name;
                            });
                            //console.log("here!",blocksWOArr,blocksWOArr.sort());
                           // $scope.$apply(function(){
                                data.blocksInOrder = blocksWOArr;
                                //console.log(data);

                                $scope.WorkOrders = data;

                          //  });


                            //console.log('for realz',$scope.blocksWOArr1,$scope.WorkOrders);
                        });



            /*
             getData
            team_leader_user_id = team.data.team_leader_user_id;
            Timesheet.get(team.data.team_leader_user_id, {
                params: {
                    start_time: start.format(),
                    end_time: end.format(),
                    group: true
                }
            }).success(function (res) {
                $scope.timesheets = res;
                loaded = true;
                timesheets = res;
                init(0);
            });
                */


        };


        $scope.findThripCount = function(date, block) {
            var data = getWorkOrders(date, block);
            var res = '';
            var thripCount = '';

            try{
                _.each(data, function(d){
                    if (d.work_order_category_id == 928){

                        res = Math.round(10*data.average_thrips,2) / 10;
                        res = '<a href="http://davewilson.tech/#/block/' + d.block_id + '/surveys?category=928&date=' + d.est_start_date + '" target="blank">' + Math.round(10*data.average_thrips,2) / 10 + "</a>";
                    }
                });


            }
            catch(e)
            {

            }


           // if(!loaded)
             ///   res = 'loading...';

            return res;
        };

        $scope.sprayActive = function(date, block) {
            var data = getWorkOrders(date, block);
            try {
                var ret = false;
                _.each(data, function(d) {
                    if(d.work_order_category_id == 929)
                    {
                        ret = true;
                    }
                });

                return ret;

            }
            catch(e)
            {
                return false;
            }

        };

        $scope.sprayClass = function(date, block) {
            var data = getWorkOrders(date, block);

            let sprayClass = ['spray'];
            try {
                var hasPur = false;
                var hasRec = false;
                _.each(data, function(d){
                    if(d.agrian_report) {
                        sprayClass.push(d.agrian_report.report_type);
                    }
                });
            } catch(e) {

            }

            return sprayClass.join(' ');
        };

        $scope.sprayInfo = function(date, block) {
            var data = getWorkOrders(date, block);

            try {
                var ret = false;
                var isPur = false;
                _.each(data, function(d, index) {
                    if(d.work_order_category_id === 929)
                    {
                        if(d.agrian_report_id !== null) {
                            ret = d;
                            isPur = true;
                        } else if(!isPur) {
                            ret = d;
                        }

                    }
                });

                return ret;

            }
            catch(e)
            {
                return false;
            }
        };

        $scope.sprayChemical = function(date, block) {
            var data = getWorkOrders(date, block);

            if(data.activeChemical) {
                return data.activeChemical;
            }
        };

        $scope.irrigationText = function(date, block) {
            var data = getWorkOrders(date, block);
            if(data.cn9) {

                if(data.hours) {
                    return 'CN9(' + data.hours + ')';
                } else {
                    return 'CN9';
                }
            } else if(data.hours) {
                return data.hours;
            }
        };

        $scope.irrigationActive = function(date, block) {
            var data = getWorkOrders(date, block);
            try {
                var ret = false;
                _.each(data, function(d) {
                    if(d.work_order_category_id == 930)
                    {
                        ret = true;
                    }
                });

                return ret;
            }
            catch(e)
            {
                return false;
            }

            return false;
        };

        $scope.irrigationColor = function(date, block) {
            var data = getWorkOrders(date, block);

            try {
                var ret = 'blue';
                _.each(data, function(d) {
                    if(d.work_order_category_id === 930 && data.tv) {
                        ret = 'green';
                    }
                });

                return ret;
            }
            catch(e)
            {
                return 'blue';
            }

            return 'blue';
        };

        /*
        $scope.blocksTable = new ngTableParams({
            page: 1,
            count: 8
        }, {
//
            getData: function ($defer, params) {

                var param = {};
              // param.categoryId = 928;
                param.per_page = 555;
                param.group = true;
                console.log($scope.start_date);
                param.start_est_date = moment($scope.start_time).format('YYYY-MM-DD');
                param.categoryIds = "928,929,930";
                param.blockNamesArr = blocksArr;
              //  param.order_by = "+block.name";
                param.end_est_date = moment($scope.end_time).format('YYYY-MM-DD');
                //param.blockName = "R1F";
                //param.blockId = 238;
                //console.log(param);
                /*
                $.ajax({
                    type: "GET",
                    dataType: "json",
                    url: "http://dwn.tilvid.com/surveys/pests?start_date=" + param.start_date + "&end_date=" + param.end_date + "&per_page=" + param.per_page,
                    success: function(response){
                        $scope.pestData = response.data;
                        console.log($scope.pestData);
                    }});

                WorkOrders.list(param)
                    .success(function (data) {
                        //console.log('here',data);
                        //params.total(data.items.total);
                        $scope.WorkOrders = data;
                        workOrdersByBlock = parseWorkOrders(data);
                        $defer.resolve(data);
                    });

            }
        });
*/

        $scope.blockHasData = function(block) {
            if(workOrdersByBlock[block]) {
                return true;
            }

            return false;
        };

        function parseWorkOrders(data) {
            var wos = [];
            _.each(data.blocks, function(workOrder) {
                wos[workOrder.block.name] = workOrder;
            });

            return wos;
        }

        function getWorkOrders(date, block) {

            if(workOrdersByBlock[block]) {
                if(workOrdersByBlock[block][date]) {
                    return workOrdersByBlock[block][date];
                }
            }

           return [];
        }


    }]);

